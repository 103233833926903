import React from 'react'
import tw, { styled } from 'twin.macro'
import { motion } from 'framer-motion'
import cardsData from './../data/cards.json'
import { useStateContext } from '../context/stateContext'
import deckRevers from '../assets/sommer/grafiken_memory_666x666_RS_01.jpg'

const CardSlot = styled(motion.div)`
	${tw`w-14 h-14 xs:w-16 xs:h-16 mobile:w-20 mobile:h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 2xl:w-44 2xl:h-44 `}
	@media (min-width: 1536px) and (max-height: 750px) {
		${tw`2xl:w-40 2xl:h-40`}
	}
	@media (min-width: 1536px) and (max-height: 700px) {
		${tw`2xl:w-36 2xl:h-36`}
	}
	@media (min-width: 1536px) and (max-height: 650px) {
		${tw`2xl:w-32 2xl:h-32`}
	}
`
const CardItem = styled(motion.div)`
	${tw`absolute cursor-pointer w-14 h-14 xs:w-16 xs:h-16 mobile:w-20 mobile:h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 2xl:w-44 2xl:h-44`}
	perspective: 800px;
	transform-style: preserve-3d;
	@media (min-width: 1536px) and (max-height: 750px) {
		${tw`2xl:w-40 2xl:h-40`}
	}
	@media (min-width: 1536px) and (max-height: 700px) {
		${tw`2xl:w-36 2xl:h-36`}
	}
	@media (min-width: 1536px) and (max-height: 650px) {
		${tw`2xl:w-32 2xl:h-32`}
	}
`
const CardFront = styled(motion.div)`
	${tw`absolute flex items-center justify-center bg-contain w-14 h-14 xs:w-16 xs:h-16 mobile:w-20 mobile:h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 2xl:w-44 2xl:h-44 transform-gpu`}
	transform-style: preserve-3d;
	perspective: 800px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.45);
	backface-visibility: hidden;
	transform-origin: 50% 50%;
	@media (min-width: 1536px) and (max-height: 750px) {
		${tw`2xl:w-40 2xl:h-40`}
	}
	@media (min-width: 1536px) and (max-height: 700px) {
		${tw`2xl:w-36 2xl:h-36`}
	}
	@media (min-width: 1536px) and (max-height: 650px) {
		${tw`2xl:w-32 2xl:h-32`}
	}

	&::after {
		content: '';
		top: 2px;
		left: 2px;
		right: 2px;
		bottom: 2px;
		${tw`absolute`}
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
	}
`
const CardBehind = styled(motion.div)`
	${tw`absolute flex items-center justify-center bg-contain w-14 h-14 xs:w-16 xs:h-16 mobile:w-20 mobile:h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 2xl:w-44 2xl:h-44 transform-gpu`}
	transform-style: preserve-3d;
	perspective: 800px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.45);
	backface-visibility: hidden;
	transform-origin: 50% 50%;
	@media (min-width: 1536px) and (max-height: 750px) {
		${tw`2xl:w-40 2xl:h-40`}
	}
	@media (min-width: 1536px) and (max-height: 700px) {
		${tw`2xl:w-36 2xl:h-36`}
	}
	@media (min-width: 1536px) and (max-height: 650px) {
		${tw`2xl:w-32 2xl:h-32`}
	}

	&::after {
		content: '';
		top: 2px;
		left: 2px;
		right: 2px;
		bottom: 2px;
		${tw`absolute`}
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
	}
`

const Card = ({ order, index, cardClickHandle, foldedPairs, isPair, notPair, firstCardSelected, secondCardSelected, uniqueKey, flipDeckSrc }) => {
	const { animationState } = useStateContext()
	const onCardClick = () => {
		if (foldedPairs.includes(index)) return
		if (!animationState && !isPair && uniqueKey !== firstCardSelected.key && secondCardSelected.key === '') {
			cardClickHandle(index, uniqueKey)
		}
		if (notPair && !animationState) cardClickHandle()
	}

	const flipAnimation = {
		initial: {
			rotateY: 0,
			scale: 1,
			transition: {
				ease: [0.4, 0.2, 0.2, 1],
				duration: 0.7,
			},
		},
		flipped: {
			rotateY: 180,
			scale: [1, 1.4],
			transition: {
				ease: [0.4, 0.2, 0.2, 1],
				duration: 0.7,
			},
		},
		flippedReverse: {
			rotateY: -180,
			scale: [1, 1.4],
			transition: {
				ease: [0.4, 0.2, 0.2, 1],
				duration: 0.7,
			},
		},
	}

	return (
		<CardSlot
			data-index={index}
			data-unique={uniqueKey}
			onClick={onCardClick}
			style={{
				order: order,
			}}
		>
			<CardItem>
				<CardFront
					initial='initial'
					style={{
						backgroundImage: `url(${deckRevers})`,
						WebkitBackfaceVisibility: 'hidden',
					}}
					animate={foldedPairs.includes(index) || firstCardSelected.key === uniqueKey || secondCardSelected.key === uniqueKey ? 'flipped' : 'initial'}
					variants={flipAnimation}
					transition={{ duration: 1 }}
				></CardFront>
				<CardBehind
					initial='flippedReverse'
					style={{
						backgroundImage: `url(${flipDeckSrc})`,
						WebkitBackfaceVisibility: 'hidden',
					}}
					animate={foldedPairs.includes(index) || firstCardSelected.key === uniqueKey || secondCardSelected.key === uniqueKey ? 'initial' : 'flippedReverse'}
					variants={flipAnimation}
					transition={{ duration: 1 }}
				>
					{cardsData[index - 1].frontUrlDE}
				</CardBehind>
			</CardItem>
		</CardSlot>
	)
}

export default Card
