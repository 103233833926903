import React from 'react'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '../context/stateContext'
import { motion } from 'framer-motion'

const Container = styled(motion.div)`
	${tw`absolute z-20 items-center justify-center w-8 h-8 border rounded-full cursor-pointer md:h-10 md:w-10 border-lightGrey`}
`
const Sound = ({ mode, mobile }) => {
	const stateContext = useStateContext()

	const toggleSound = (state) => {
		stateContext.dispatch({ action: 'SET_SOUND', data: state })
	}

	return (
		<Container
			className={mode === 'game' ? 'flex right-10 top-1/2 transform -translate-y-1/2' : mobile ? 'top-2 left-5 flex md:hidden' : 'hidden md:flex top-4 right-10'}
			onClick={() => {
				toggleSound(!stateContext.state.sound)
			}}
		>
			{stateContext.state.sound && (
				<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M0 5.77V11.77H4L9 16.77V0.77L4 5.77H0ZM7 5.6V11.94L4.83 9.77H2V7.77H4.83L7 5.6ZM13.5 8.77C13.5 7 12.48 5.48 11 4.74V12.79C12.48 12.06 13.5 10.54 13.5 8.77ZM11 0V2.06C13.89 2.92 16 5.6 16 8.77C16 11.94 13.89 14.62 11 15.48V17.54C15.01 16.63 18 13.05 18 8.77C18 4.49 15.01 0.91 11 0Z'
						fill='#0069A4'
					/>
				</svg>
			)}
			{!stateContext.state.sound && (
				<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M2.33993 0.930176L0.929932 2.34018L5.28993 6.70018L4.99993 7.00018H0.999932V13.0002H4.99993L9.99993 18.0002V11.4102L14.1799 15.5902C13.5299 16.0802 12.7999 16.4702 11.9999 16.7002V18.7602C13.3399 18.4602 14.5699 17.8402 15.6099 17.0102L17.6599 19.0602L19.0699 17.6502L2.33993 0.930176ZM7.99993 13.1702L5.82993 11.0002H2.99993V9.00018H5.82993L6.70993 8.12018L7.99993 9.41018V13.1702ZM16.9999 10.0002C16.9999 10.8202 16.8499 11.6102 16.5899 12.3402L18.1199 13.8702C18.6799 12.7002 18.9999 11.3902 18.9999 10.0002C18.9999 5.72018 16.0099 2.14018 11.9999 1.23018V3.29018C14.8899 4.15018 16.9999 6.83018 16.9999 10.0002ZM9.99993 2.00018L8.11993 3.88018L9.99993 5.76018V2.00018ZM14.4999 10.0002C14.4999 8.23018 13.4799 6.71018 11.9999 5.97018V7.76018L14.4799 10.2402C14.4899 10.1602 14.4999 10.0802 14.4999 10.0002Z'
						fill='black'
					/>
				</svg>
			)}
		</Container>
	)
}

export default Sound
