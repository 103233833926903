import React, { createContext, useContext, useReducer } from 'react'
import { Howl } from 'howler'

const initialState = {
	gameStatus: 'beforeInit',
	locale: 'de',
	screenOrientation: '',
	popup: false,
	finalScore: {
		rounds: 1,
		pairs: 0,
	},
	animation: false,
	sound: true,
	soundLoaded: false,
	sounds: {
		click: null,
		uncover: null,
		cover: null,
		win: null,
		pair: null,
		pairNumber: null,
		transition: null,
		spawn_001: null,
		spawn_002: null,
		spawn_003: null,
	},
}

const stateReducer = (state, payload) => {
	switch (payload.action) {
		case 'SET_GAME_STATUS':
			return { ...state, gameStatus: payload.data }
		case 'SET_LOCALE':
			return { ...state, locale: payload.data }
		case 'SET_POPUP_STATUS':
			return { ...state, popup: payload.data }
		case 'SET_FINAL_SCORE':
			return { ...state, finalScore: { rounds: payload.rounds, pairs: payload.pairs } }
		case 'RESET_GAME':
			return { ...state, finalScore: { rounds: 1, pairs: 0 } }
		case 'SET_ANIMATION':
			return { ...state, animation: payload.data }
		case 'SET_SOUND_LOADED':
			return { ...state, soundLoaded: payload.data }
		case 'SET_SOUND':
			return { ...state, sound: payload.data }
		case 'SET_ORIENTATION_SCREEN':
			return { ...state, screenOrientation: payload.data }
		case 'SET_SOUND_FILE':
			return { ...state, sounds: { ...state.sounds, [payload.data]: payload.src } }
		case 'SET_SOUND_SPAWNS':
			return { ...state, sounds: { ...state.sounds, spawn: payload.data } }
		default:
			return state
	}
}

export const StateContext = createContext({})

const StateContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(stateReducer, initialState)
	let channels = []
	let index = 0
	let timeout = 0

	const loadSound = () => {
		Object.keys(state.sounds).forEach(async (audio) => {
			if (audio === 'transition') return
			const newSound = await import(`../assets/sounds/sound_${audio}.wav`).then((wav) => {
				return new Howl({
					src: [wav.default],
					volume: 0.5,
				})
			})
			dispatch({ action: 'SET_SOUND_FILE', data: audio, src: newSound })
		})
	}

	const replaySound = (audio, spawn) => {
		channels.push(audio)

		if (spawn) {
			timeout = timeout + 120
			playChannel(index, timeout, audio)
		} else {
			playChannel(index, 0, audio)
		}
		index++
	}
	const playChannel = (index, timeout = 0) => {
		setTimeout(() => {
			channels[index].play()
		}, timeout)
	}

	const StateContextStore = {
		state,
		animationState: state.animation,
		setAnimation: () => dispatch({ action: 'SET_ANIMATION', data: true }),
		setEndAnimation: () => dispatch({ action: 'SET_ANIMATION', data: false }),
		playSound: (audio, spawn = false) => replaySound(state.sounds[audio], spawn),
		loadSounds: () => {
			dispatch({ action: 'SET_SOUND_LOADED', data: true })
			loadSound()
		},
		dispatch,
	}

	return <StateContext.Provider value={StateContextStore}>{children}</StateContext.Provider>
}
export default StateContextProvider

export const useStateContext = () => useContext(StateContext)
