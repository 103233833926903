import Screen from './components/Screen'
import StateContextProvider from './context/stateContext'

function App() {
	return (
		<StateContextProvider>
			<div className='app'>
				<Screen />
			</div>
		</StateContextProvider>
	)
}

export default App
